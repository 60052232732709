
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";

import { useStore } from "vuex";

export default defineComponent({
  name: "kt-widget-9",
  components: {},
  props: {
    widgetClasses: String,
  },
  methods: {
    createAccount() {
      this.$router.push({ path: "/tradecopy/createmainaccount" });
    },
  },
  setup() {
    const store = useStore();
    const checked = ref(false);
    const mainAccounts = ref(null);

    function fetchData() {
      store.dispatch(Actions.GET_ACCOUNT).then((data) => {
        mainAccounts.value = data.data;
      });
    }

    // eslint-disable-next-line no-undef
    onMounted(() => {
      fetchData();
    });
    return {
      mainAccounts,
      checked,
    };
  },
});
